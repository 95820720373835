import { useEffect, useState } from "react";

interface Props {
    icon?: true
}

const Whatsapp = ({ icon }: Props) => {
    const [urlW, setUrlW] = useState<string>('');

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setUrlW(process.env.REACT_APP_WHATSAPPRESPONSIVE!);
        } else {
            setUrlW(process.env.REACT_APP_WHATSAPP!);
        }
    }, [navigator.userAgent]);

    const handleClickWhatsApp = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(process.env.REACT_APP_WHATSAPPRESPONSIVE);
        } else {
            window.open(process.env.REACT_APP_WHATSAPP);
        }
    }

    if (icon) {
        return (
            <a href={urlW} rel="noreferrer" className="whatsapp" target="_blank" style={{ cursor: 'pointer', color: 'white' }}>
                <i style={{ fontSize: '35px' }} className="fab fa-whatsapp whatsapp-icon"></i>
            </a>)
    } else {
        return (
            <button onClick={handleClickWhatsApp} className="btn whatsapp-button"> <i style={{ marginLeft: "10px", marginRight: "10px" }} className="fab fa-whatsapp"></i>Consulta gratis</button>

        )
    }



}

export default Whatsapp;