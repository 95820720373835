import Contacto from "./component/contacto";
import Phone from "./component/generic/phone";
import Whatsapp from "./component/generic/whatsapp";
import MagiaBlanca from "./component/magia-blanca";
import MagiaNegra from "./component/magia-negra";
import MagiaVerde from "./component/magia-verde";


function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand logo" href="/">Marta Molina Jaramillo</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-links" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse text-capitalize" id="nav-links">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#home">Inicio <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#magia-blanca">Magia blanca</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#magia-verde">Magia verde</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#magia-negra">Magia negra</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contacto</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header id="home">
        <div className="content">
          <div className="d-flex align-items-center flex-column justify-content-center">
            <h1>Bienvenidos</h1>
            <p><strong>Mi magia es negra y rebelde, es la más fuerte, soy bruja desde el vientre, mis antepasados me heredaron lo que soy.</strong></p>
            <p>Mis trabajos son efectivos y sustanciales, con la <strong>Santa Muerte</strong> puedo con cualquiera e inclusive con el demonio, no hay persona, poder o ente que se me resista, en Biznaga, Coahuila, México, me conocen porque soy la mera mera bruja - “La bruja que vuela”, la más poderosa, mi trabajo es servirte, hacer que tus deseos se cumplan y satisfacer tus necesidades.</p>

            <div className="row" style={{ justifyContent: "center", width: "100%" }}>
              <div className="col-md-6 col-lg-8 col-sm-10 col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                <Whatsapp />
              </div>
            </div>
          </div>
        </div>
        <div id="slideToNext" className="carousel slide" >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/img/principal.jpg" className="d-block w-100" alt="Principal" />
            </div>
            <div className="carousel-item">
              <img src="/img/principal.jpg" className="d-block w-100" alt="Principal" />
            </div>
            <div className="carousel-item">
              <img src="/img/principal.jpg" className="d-block w-100" alt="Principal" />
            </div>
          </div>
        </div>
      </header>
      <MagiaBlanca />
      <MagiaVerde />
      <MagiaNegra />
      <Contacto />
      <Whatsapp icon />
      <Phone />
    </>

  );
}

export default App;
