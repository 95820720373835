const Contacto = () => {

    const handleClickWhatsApp = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open(process.env.REACT_APP_WHATSAPPRESPONSIVE);
        } else {
            window.open(process.env.REACT_APP_WHATSAPP);
        }
    }

    return (
        <footer id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 footer-text">
                        <div style={{ textAlign: "center", marginBottom: '10px' }}>
                            <h1>Contáctame</h1>

                        </div>
                        <p>
                            ¡No sufras más!, ¿Vas a seguir esperando?,
                            contáctame ahora y recibe tu consulta personalizada, escoge el medio por el cual quieres comunicarte, para recibir pronto tu consulta.
                        </p>
                        <ul className="contact-icons">
                            <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61559098825739" rel="noreferrer"><i className="fab fa-facebook-square fa-2x"></i></a></li>
                            <li><a style={{ cursor: 'pointer' }} className="wht" onClick={handleClickWhatsApp}><i className="fab fa-whatsapp fa-2x"></i></a></li>
                        </ul>

                    </div>
                    <div className="col-md-6">
                        <img src="/img/footer.jpg" height={400} alt="Marta Molina" />
                    </div>

                </div>
                <div style={{ marginTop: '50px' }} className="copyright">
                    <p>© {new Date().getFullYear()} Todos los derechos reservados Marta Molina</p>
                </div>
            </div>
        </footer>
    )
}

export default Contacto;