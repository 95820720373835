import Whatsapp from "./generic/whatsapp";

const MagiaBlanca = () => {

    return (
        <div className="about" id="magia-blanca">
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="img"><img src="/img/magia-blanca.webp" alt="MagiaBlanca" /></div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="about-child">
                        <h2 className="text-uppercase">Magia blanca</h2>

                        <p>Este tipo de magia se enfoca a lo espiritual, te ayudare a combatir hechizos malignos, conservar tu integridad y atraer prosperidad.<br /><br />

                            Cuando padezcas de cansancio, pensamientos negativos o sensaciones de tristeza y depresión, te ayudare a purificar tu campo energético y espiritual.</p>
                        <p>Servicios</p>
                        <ul>
                            <li style={{ listStyleType: "initial" }}>Atracción de empleo</li>
                            <li style={{ listStyleType: "initial" }}>Embarazos</li>
                            <li style={{ listStyleType: "initial" }}>Endulzamientos</li>
                            <li style={{ listStyleType: "initial" }}>Sanaciones</li>
                            <li style={{ listStyleType: "initial" }}>Protección</li>
                        </ul>
                        <div className="row" style={{ justifyContent: "end", width: "100%" }}>
                            <div className="col-md-6 col-lg-8 col-sm-10 col-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Whatsapp />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MagiaBlanca;