import Whatsapp from "./generic/whatsapp";

const MagiaVerde = () => {

    return (
        <div className="about" style={{ backgroundColor: "#ebebeb" }} id="magia-verde">
            <div className="row">

                <div className="col-lg-6 col-12">
                    <div className="about-child magia-verde">
                        <h2 className="text-uppercase">Magia verde</h2>
                        <p>Este tipo de magia se enfoca a la madre naturaleza, recurro a las plantas milenarias para obtener pociones, con esto te daré beneficios físicos o espirituales, podrás atraer amor, salud, suerte, fortuna, protecciones, entre otros.</p>
                        <p>Servicios</p>
                        <ul>
                            <li style={{ listStyleType: "initial" }}>Curaciones a través del agua, fuego, tierra, viento</li>
                            <li style={{ listStyleType: "initial" }}>Lectura cigarro grueso</li>
                            <li style={{ listStyleType: "initial" }}>Limpias</li>
                            <li style={{ listStyleType: "initial" }}>Lectura de pentaculo</li>
                        </ul>
                        <div className="row" style={{ justifyContent: "end", width: "100%" }}>
                            <div className="col-md-6 col-lg-8 col-sm-10 col-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Whatsapp />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12" style={{ display: 'flex', justifyContent: 'end' }}>
                    <div className="img top-img-magia-verde"><img src="/img/magia-verde.webp" alt="MagiaVerde" /></div>
                </div>


            </div>
        </div>
    )

}

export default MagiaVerde;