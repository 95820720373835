import Whatsapp from "./generic/whatsapp";

const MagiaNegra = () => {
    return (
        <div className="about" style={{ backgroundColor: "#151201", color: "white" }} id="magia-negra">
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="img"><img src="/img/magia-negra.webp" alt="MagiaNegra" /></div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="about-child">
                        <h2 className="text-uppercase">Magia negra</h2>
                        <p>Este tipo de magia se enfoca a dominar o controlar a cualquier persona o la naturaleza, te ayudare a conseguir lo que quieras, de la mano de la <strong>Santa Muerte</strong> soy la más fuerte e implacable, mi pacto con ella es indestructible lo que me da mucho más poder y efectividad.</p>
                        <p>Servicios</p>
                        <ul>
                            <li style={{ listStyleType: "initial" }}>Abortos</li>
                            <li style={{ listStyleType: "initial" }}>Alejamientos</li>
                            <li style={{ listStyleType: "initial" }}>Amarres</li>
                            <li style={{ listStyleType: "initial" }}>Atracción de dinero</li>
                            <li style={{ listStyleType: "initial" }}>Barajas Tarot</li>
                            <li style={{ listStyleType: "initial" }}>Desentierros</li>
                            <li style={{ listStyleType: "initial" }}>Entierros</li>
                            <li style={{ listStyleType: "initial" }}>Muertes</li>
                            <li style={{ listStyleType: "initial" }}>Protección</li>
                        </ul>
                        <div className="row" style={{ justifyContent: "end", width: "100%" }}>
                            <div className="col-md-6 col-lg-8 col-sm-10 col-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Whatsapp />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MagiaNegra;